import React from "react";
import {User} from "../util/Types";

export const UserContext = React.createContext({
    user: {
        name: 'None',
        token: '',
        loggedIn: false
    },
    setUser: (user: User) => {}
});
