import React, {useState} from 'react';
import './App.css';
import {PageSwitch} from "./navigation/PageSwitch";
import {LoginPage} from "./login/LoginPage";
import { UserContext } from './login/UserContext';
import {User} from "./util/Types";

require('popper.js');
require('bootstrap');

function App() {
    const [user, setUser] = useState({
        name: 'None',
        token: '',
        loggedIn: false
    });

    const switchUser = (user: User) => setUser(user);

    console.log('Rendering app');
    console.log('user');
    console.log(user);

    if (user.loggedIn) {
        return (
            <div className="App">
                <UserContext.Provider value={{user, setUser: switchUser}}>
                    <PageSwitch/>
                </UserContext.Provider>
            </div>
        );
    } else {
        return (
            <div className="App">
                <UserContext.Provider value={{user, setUser: switchUser}}>
                    <LoginPage/>
                </UserContext.Provider>
            </div>
        );
    }
}

export default App;
