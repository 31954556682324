import {postBody, postBodyAuthenticated} from "../util/Requests";
import {loginURL, logoutURL} from "../util/Globals";

export const loginRequest = (username: string, password: string) => {
    return postBody(loginURL, {username, password});
};

export const logoutRequest = (token: string) => {
    return postBodyAuthenticated(logoutURL, token);
};
