import React, {FormEvent, useContext, useRef, useState} from "react";
import {Alert, Button, Card, Container, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faKey, faUserAlt} from "@fortawesome/free-solid-svg-icons";
import {postBody} from "../util/Requests";
import {loginURL} from "../util/Globals";
import {ResponseWrapper} from "../util/Types";
import {UserContext} from "./UserContext";
import {loginRequest} from "../requests/sessions";

export const LoginPage: React.FC = (): JSX.Element => {
    const usernameInput = useRef<HTMLInputElement>(null);
    const passwordInput = useRef<HTMLInputElement>(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('none');

    const userContext = useContext(UserContext);

    const loginAttempt = () => {
        if (loading) {
            return;
        }

        if (!usernameInput || !usernameInput.current || !passwordInput || !passwordInput.current) {
            setError('An Error occurred, please try again');
            return;
        }

        const username = usernameInput.current.value;
        const password = passwordInput.current.value;

        setLoading(true);
        setError('none');

        loginRequest(username, password)
            .then((data) => {

                const wrapper = data as ResponseWrapper;
                setLoading(false);
                console.log(data);

                if (wrapper && wrapper.error && wrapper.result) {
                    if (wrapper.error === 'none') {
                        userContext.setUser({
                            name: username,
                            token: wrapper.result.token,
                            loggedIn: true
                        });

                        console.log('New user set');
                    } else {
                        setError(wrapper.error.toString());
                    }
                } else {
                    setError('Error: Malformed Server Response. Please contact an administrator');
                }
            })
            .catch(reason => {
                setLoading(false);
                setError('Could not reach the server, check your network connection');
                console.log(reason);
            });
    };

    const handleLogin = (event: FormEvent) => {
      event.preventDefault();
      loginAttempt();
    };

    return (
      <div>
          <div style={{height: '3em'}}/>
          <Container>
              <Card bg={"dark"} text={"white"}>
                  <Card.Header>
                      Welcome to the User Management Web Interface!
                  </Card.Header>
                  <Card.Body>
                      <Form onSubmit={handleLogin}>
                          <Form.Group controlId={"loginFormUsername"}>
                              <InputGroup>
                                  <InputGroup.Prepend>
                                      <InputGroup.Text><FontAwesomeIcon icon={faUserAlt}/></InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control ref={usernameInput} placeholder={"Enter Username"}/>
                              </InputGroup>
                          </Form.Group>

                          <Form.Group controlId={"loginFormPassword"}>
                              <InputGroup>
                                  <InputGroup.Prepend>
                                      <InputGroup.Text><FontAwesomeIcon icon={faKey}/></InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control ref={passwordInput} type={"password"} placeholder={"Enter Password"}/>
                              </InputGroup>
                          </Form.Group>

                          <Button variant="success" type="submit" block disabled={loading}>
                              {loading ? 'Loading...' : 'Log In'}
                          </Button>
                      </Form>
                  </Card.Body>
                  <Card.Footer>
                      { error === 'none' ? <span/> : (<Alert variant="danger"><FontAwesomeIcon icon={faExclamationCircle}/> {error}</Alert>) }
                  </Card.Footer>
              </Card>
          </Container>
      </div>
    );
};
