export type User = {
    name: string,
    token: string,
    loggedIn: boolean
};

export type ResponseWrapper = {
    result: any,
    error: string | string[]
};

export const emptyUser = (): User => {
    return {
        name: 'None',
        token: '',
        loggedIn: false
    };
};

export type RoleSummary = {
    id: string,
    name: string,
    created: number,
    updated: number
};

export type UserSummary = {
    id: string,
    name: string,
    mail: string | null,
    created: string,
    updated: string
};

export type UserPermissionSummary = {
    id: string,
    user: string,
    permission: string
};

export type UserRoleSummary = {
    id: string,
    roleID: string,
    roleName: string
};

export type AssociationSummary = {
    key: string,
    value: string
};
