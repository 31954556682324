import React, {FormEvent, useContext, useEffect, useRef, useState} from "react";
import {UserContext} from "../../login/UserContext";
import {AssociationSummary, ResponseWrapper, UserSummary} from "../../util/Types";
import {associationBaseURL, listAssociationsURL, noError, userURL} from "../../util/Globals";
import {getAuthenticated, postBodyAuthenticated} from "../../util/Requests";
import {Card, Col, Container, Form, ListGroup, Row} from "react-bootstrap";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import {IconButton} from "../../components/IconButton";
import {faArrowRight, faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const AssociationsPage: React.FC = (): JSX.Element => {

    const keyInputRef = useRef(null);
    const valueInputRef = useRef(null);

    const userContext = useContext(UserContext);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | string[]>('none');
    const [users, setUsers] = useState<UserSummary[]>([]);
    const [associations, setAssociations] = useState<AssociationSummary[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<UserSummary[]>([]);

    const [highlightedUser, setHighlightedUser] = useState('');

    const loadUsers = () => {
        setLoading(true);
        setError(noError);

        getAuthenticated(userURL, userContext.user.token)
            .then((response) => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    console.log(wrapper.result);
                    setUsers(wrapper.result);

                    // Reset highlighted role if it doesn't exist for some reason (e.g. deleted)
                    if (highlightedUser !== '') {
                        let contained = false;
                        for (let user of users) {
                            if (user.id === highlightedUser) {
                                contained = true;
                            }
                        }

                        if (!contained) {
                            setHighlightedUser('');
                        }
                    }
                } else {
                    setError(wrapper.error);
                }

                setLoading(false);
            });
    };

    const loadUserAssociations = (userID: string) => {
        setLoading(true);
        setError(noError);

        const url = listAssociationsURL + '/' + userID;

        getAuthenticated(url, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    setAssociations(wrapper.result);
                } else {
                    setError(wrapper.error);
                }
            })
            .catch(reason => {

            })
            .finally(() => setLoading(false));
    };

    const createAssociationHandler = (event?: FormEvent) => {
        if (event) {
            event.preventDefault();
        }

        setLoading(true);
        setError(noError);

        postBodyAuthenticated(associationBaseURL, userContext.user.token, {
            user: highlightedUser,
            // @ts-ignore
            key: keyInputRef.current.value,
            // @ts-ignore
            value: valueInputRef.current.value
        })
            .then(response => {
                const wrapper = response as ResponseWrapper;
                if (wrapper.error === noError) {
                    loadUserAssociations(highlightedUser);
                } else {
                    setError(wrapper.error);
                }

                setLoading(false);
            })
            .catch(reason => setError(reason.toString()));
    };

    const selectUser = (userID: string) => {
        setHighlightedUser(userID);
        loadUserAssociations(userID);
    };

    useEffect(loadUsers, []);

    return (
        <Container fluid>
            <VerticalPlaceholder height="1.5em"/>

            <Row>
                <Col>
                    <Card bg={"dark"} text={"white"}>
                        <Card.Header/>
                        <Card.Body>
                            <ListGroup>
                                {users.map(currentUser => (
                                    <ListGroup.Item
                                        className={currentUser.id === highlightedUser ? "activeListGroupItem" : "inactiveListGroupItem"}
                                        style={{textAlign: 'left', cursor: 'pointer'}}
                                        key={currentUser.id} onClick={() => selectUser(currentUser.id)}>
                                        <span>{currentUser.name}</span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                        <Card.Footer/>
                    </Card>
                </Col>

                <VerticalPlaceholder height="1.5em"/>

                <Col>
                    <Card bg={"dark"} text={"white"}>
                        <Card.Header>
                            <Form inline onSubmit={createAssociationHandler}>
                                <Form.Control ref={keyInputRef} className="mr-2" placeholder="Enter Key"/>
                                <Form.Control ref={valueInputRef} className="mr-2" placeholder="Enter Value"/>

                                <IconButton onClick={createAssociationHandler} variant="success" icon={faPlus}
                                            loading={loading}/>
                            </Form>
                        </Card.Header>
                        <Card.Body>
                            <ListGroup>
                                {associations.map((currentAssociation, index) => (
                                    <ListGroup.Item
                                        className="inactiveListGroupItem"
                                        style={{textAlign: 'left', cursor: 'pointer'}}
                                        key={index}>
                                        <span>{currentAssociation.key} <FontAwesomeIcon
                                            icon={faArrowRight}/> {currentAssociation.value}</span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                        <Card.Footer/>
                    </Card>
                </Col>
            </Row>

        </Container>
    );
};
