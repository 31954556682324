export const noError = 'none';

export const baseURL = '';
export const loginURL = baseURL + 'login';
export const logoutURL = baseURL + 'logout';
export const userURL = baseURL + 'users';
export const roleURL = baseURL + 'roles';
export const rolePermissionsURL = baseURL + 'role-permissions';
export const userRolesURL = baseURL + 'user-roles';
export const userPermissionsURL = baseURL + 'user-permissions';
export const associationBaseURL = baseURL + 'associations';
export const listAssociationsURL = associationBaseURL + '/read/others/list';
