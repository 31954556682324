import React, {FormEvent, useContext, useEffect, useRef, useState} from "react";
import {Alert, Button, Card, Col, Container, Form, FormControl, FormGroup, ListGroup, Row} from "react-bootstrap";
import {faExclamationCircle, faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import {ResponseWrapper, RoleSummary, UserPermissionSummary, UserRoleSummary, UserSummary} from "../../util/Types";
import {IconButton} from "../../components/IconButton";
import {deleteBodyAuthenticated, getAuthenticated, postBodyAuthenticated} from "../../util/Requests";
import {noError, roleURL, userPermissionsURL, userRolesURL, userURL} from "../../util/Globals";
import {UserContext} from "../../login/UserContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const UsersPage: React.FC = (): JSX.Element => {
    const usernameInputRef = useRef(null);
    const mailInputRef = useRef(null);
    const passwordInputRef = useRef(null);

    const roleInputRef = useRef(null);

    const permissionInputRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | string[]>('none');
    const [users, setUsers] = useState<UserSummary[]>([]);

    const [highlightedUser, setHighlightedUser] = useState('');

    const [permissions, setPermissions] = useState<string[]>([]);
    const [userRoles, setUserRoles] = useState<UserRoleSummary[]>([]);

    const [roles, setRoles] = useState<RoleSummary[]>([]);

    const userContext = useContext(UserContext);

    const loadUsers = () => {
        setLoading(true);
        setError(noError);

        getAuthenticated(userURL, userContext.user.token)
            .then((response) => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    console.log(wrapper.result);
                    setUsers(wrapper.result);

                    // Reset highlighted role if it doesn't exist for some reason (e.g. deleted)
                    if (highlightedUser !== '') {
                        let contained = false;
                        for (let user of users) {
                            if (user.id === highlightedUser) {
                                contained = true;
                            }
                        }

                        if (!contained) {
                            setHighlightedUser('');
                        }
                    }
                } else {
                    setError(wrapper.error);
                }
                
                setLoading(false);
            });
    };

    const loadRoles = () => {
        setLoading(true);
        setError(noError);

        getAuthenticated(roleURL, userContext.user.token)
            .then((response) => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    console.log(wrapper.result);
                    setRoles(wrapper.result);
                } else {
                    setError(wrapper.error);
                }

                setLoading(false);
            });
    };

    const loadUserRoles = (user: string) => {
        setLoading(true);
        setError(noError);

        const url = userRolesURL + '?user=' + user;

        getAuthenticated(url, userContext.user.token)
            .then((response) => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    console.log(wrapper.result);
                    setUserRoles(wrapper.result);
                } else {
                    setError(wrapper.error);
                }

                setLoading(false);
            });
    };

    const loadUserPermissions = (user: string) => {
        setLoading(true);
        setError(noError);

        const url = userPermissionsURL + '?user=' + user;

        getAuthenticated(url, userContext.user.token)
            .then((response) => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    console.log(wrapper.result);
                    setPermissions(wrapper.result);
                } else {
                    setError(wrapper.error);
                }

                setLoading(false);
            });
    };

    const addPermissionHandler = (event?: FormEvent) => {
        if (event) {
            event.preventDefault();
        }

        setLoading(true);
        setError(noError);
        const userID = highlightedUser;

        postBodyAuthenticated(userPermissionsURL, userContext.user.token, {
            user: userID,
            // @ts-ignore
            permission: permissionInputRef.current.value
        })
            .then((response) => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    loadUserPermissions(userID);
                } else {
                    setError(wrapper.error);
                }

                setLoading(false);
            });
    };

    const addRoleHandler = (event?: FormEvent) => {
        if (event) {
            event.preventDefault();
        }

        setLoading(true);
        setError(noError);
        const userID = highlightedUser;

        postBodyAuthenticated(userRolesURL, userContext.user.token, {
            user: userID,
            // @ts-ignore
            role: roleInputRef.current.value
        })
            .then((response) => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    loadUserRoles(userID);
                } else {
                    setError(wrapper.error);
                }

                setLoading(false);
            });
    };

    const revokePermissionHandler = (permission: string) => {
        setLoading(true);
        setError(noError);

        const userID = highlightedUser;

        deleteBodyAuthenticated(userPermissionsURL, userContext.user.token, {
            user: highlightedUser,
            permission
        })
            .then((response) => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    loadUserPermissions(userID);
                } else {
                    setError(wrapper.error);
                }

                setLoading(false);
            });
    };

    const revokeRoleHandler = (role: string) => {
        setLoading(true);
        setError(noError);

        const userID = highlightedUser;

        deleteBodyAuthenticated(userRolesURL, userContext.user.token, {
            user: highlightedUser,
            role
        })
            .then((response) => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    loadUserRoles(userID);
                } else {
                    setError(wrapper.error);
                }

                setLoading(false);
            });
    };

    const createAccountHandler = (event?: FormEvent) => {
        if (event) {
            event.preventDefault();
        }

        if (!usernameInputRef || !usernameInputRef.current || !mailInputRef || !mailInputRef.current || !passwordInputRef || !passwordInputRef.current) {
            return;
        }

        setError(noError);
        setLoading(true);

        postBodyAuthenticated(userURL, userContext.user.token, {
            // @ts-ignore
            username: usernameInputRef.current.value,
            // @ts-ignore
            mail: mailInputRef.current.value,
            // @ts-ignore
            password: passwordInputRef.current.value
        })
            .then((response) => {
                const wrapper = response as ResponseWrapper;
                if (wrapper.error === noError) {
                    setLoading(false);
                    loadUsers();
                } else {
                    setError(wrapper.error);
                    setLoading(false);
                }
            })
            .catch(reason => {
                console.log(reason);
                setError('Request could not be completed. Check console for more info');
                setLoading(false);
            });
    };

    const deleteUserHandler = (userID: string) => {
        setLoading(true);
        setError(noError);

        deleteBodyAuthenticated(userURL, userContext.user.token, {user: userID})
            .then(response => {
                const wrapper = response as ResponseWrapper;
                if (wrapper.error === noError) {
                    setLoading(false);
                    loadUsers();
                } else {
                    setError(wrapper.error);
                    setLoading(false);
                }
            })
            .catch(reason => {
                console.log(reason);
                setError('Request could not be completed. Check console for more info');
                setLoading(false);
            });
    };

    const selectUser = (user: string) => {
        loadUserRoles(user);
        loadUserPermissions(user);
        setHighlightedUser(user);
    };

    useEffect(() => {
        loadUsers();
        loadRoles();
    }, []);

    return (
        <Container>
            <VerticalPlaceholder height="1.5em"/>
            <Card bg={"dark"} text={"white"}>
                <Card.Header>
                    <Form inline onSubmit={createAccountHandler}>
                        <Form.Control ref={usernameInputRef} className="mr-2" placeholder='Enter Username'/>
                        <Form.Control ref={mailInputRef} className="mr-2" type="email" placeholder='Enter Mail'/>
                        <Form.Control ref={passwordInputRef} className="mr-2" type="password"
                                      placeholder='Enter Password'/>

                        <IconButton onClick={createAccountHandler} variant="success" icon={faPlus} loading={loading}/>
                    </Form>
                </Card.Header>
                <Card.Body>
                    <ListGroup>
                        {users.map(currentUser => (
                            <ListGroup.Item onClick={() => selectUser(currentUser.id)}
                                            className={currentUser.id === highlightedUser ? 'activeListGroupItem' : 'inactiveListGroupItem'}
                                            style={{textAlign: 'left', cursor: 'pointer'}} key={currentUser.id}>
                                <span>{currentUser.name}</span>
                                <span className="ml-3" style={{fontSize: "smaller"}}># {currentUser.id}</span>
                                <IconButton disabled={currentUser.name === 'root'} style={{float: 'right'}}
                                            variant={"danger"} icon={faTrashAlt} loading={loading} onClick={() => {
                                    deleteUserHandler(currentUser.id);
                                }}/>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card.Body>
                <Card.Footer/>
            </Card>

            <VerticalPlaceholder height="1.5em"/>

            <Row>
                <Col>
                    <Card bg={"dark"} text={"white"}>
                        <Card.Header>
                            <Form inline onSubmit={addPermissionHandler}>
                                <Form.Control ref={permissionInputRef} className="mr-2" placeholder='Enter Permission'/>

                                <IconButton onClick={addPermissionHandler} variant="success" icon={faPlus}
                                            loading={loading}/>
                            </Form>
                        </Card.Header>
                        <Card.Body>
                            <ListGroup>
                                {permissions.map(currentPermission => (
                                    <ListGroup.Item className="inactiveListGroupItem" style={{textAlign: 'left'}}
                                                    key={currentPermission}>
                                        <span>{currentPermission}</span>
                                        <IconButton style={{float: 'right'}} variant={"danger"} icon={faTrashAlt}
                                                    loading={loading} onClick={() => {
                                            revokePermissionHandler(currentPermission);
                                        }}/>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                        <Card.Footer>

                        </Card.Footer>
                    </Card>
                </Col>

                <Col>
                    <Card bg={"dark"} text={"white"}>
                        <Card.Header>
                            <Form inline onSubmit={addRoleHandler}>
                                <Form.Control as="select" ref={roleInputRef} className="mr-2">
                                    {
                                        roles.map(currentRole => (
                                          <option key={currentRole.id} value={currentRole.id}>{currentRole.name}</option>
                                        ))
                                    }
                                </Form.Control>

                                <IconButton onClick={addRoleHandler} variant="success" icon={faPlus} loading={loading}/>
                            </Form>
                        </Card.Header>
                        <Card.Body>
                            <ListGroup>
                                {userRoles.map(currentRole => (
                                    <ListGroup.Item className="inactiveListGroupItem" style={{textAlign: 'left'}}
                                                    key={currentRole.id}>
                                        <span>{currentRole.roleName}</span>
                                        <IconButton style={{float: 'right'}} variant={"danger"} icon={faTrashAlt}
                                                    loading={loading} onClick={() => {
                                            revokeRoleHandler(currentRole.roleID);
                                        }}/>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                        <Card.Footer>

                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

            <VerticalPlaceholder height="1.5em"/>

            {error === noError ? '' : <Alert variant="danger"><FontAwesomeIcon icon={faExclamationCircle}/> {error}</Alert>}
        </Container>
    );
};
