import React from "react";
import {Nav} from "react-bootstrap";
import {PageContext} from "./PageContext";

interface Props {
    title: string,
    target: string
}

export const PageLink: React.FC<Props> = (props): JSX.Element => {
    return (
        <PageContext.Consumer>
            {
                ({page, setPage}) => (
                    <Nav.Link onClick={() => setPage(props.target)} className={page === props.target ? 'activeNavLink' : ''}>{props.title}</Nav.Link>
                )
            }
        </PageContext.Consumer>
    );
};
