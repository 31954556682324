import React, {useContext} from "react";
import {Button, Nav, Navbar} from "react-bootstrap";
import {PageLink} from "./PageLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt, faUserAlt} from "@fortawesome/free-solid-svg-icons";
import {UserContext} from "../login/UserContext";
import {emptyUser} from "../util/Types";
import {logoutRequest} from "../requests/sessions";

export const MainNavbar: React.FC = (): JSX.Element => {
    const userContext = useContext(UserContext);
    const logout = () => {
        userContext.setUser(emptyUser());
        logoutRequest(userContext.user.token)
            .then(result => console.log(result));
    };

    return (
        <Navbar variant={"dark"} bg={"dark"} expand={"lg"}>
            <Navbar.Toggle aria-controls={"basic-navbar-nav"}/>
            <Navbar.Collapse id={"basic-navbar-nav"}>
                <Nav variant={"pills"} className={"mr-auto"}>
                    <PageLink title={"Dashboard"} target={"dashboard"}/>
                    <PageLink title={"Users"} target={"users"}/>
                    <PageLink title={"Roles"} target={"roles"}/>
                    <PageLink title={"Transactions"} target={"transactions"}/>
                    <PageLink title={"Associations"} target={"associations"}/>
                </Nav>
                <Nav className="justify-content-end">
                    <Navbar.Text className="mr-2"><FontAwesomeIcon icon={faUserAlt}/> {userContext.user.name}</Navbar.Text>
                    <Button variant="secondary" onClick={logout}><FontAwesomeIcon icon={faSignOutAlt}/> Logout</Button>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};
