export const authHeaderName = 'x-access-token';

export const postBody = (url: string, params?: object) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: params ? JSON.stringify(params) : ''
        })
            .then(response => {
                response.json()
                    .then(resolve)
                    .catch(reject);
            })
            .catch(reject);
    });
};

export const postBodyAuthenticated = (url: string, token: string, params?: object) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token
            },
            body: params ? JSON.stringify(params) : ''
        })
            .then(response => {
                response.json()
                    .then(resolve)
                    .catch(reject);
            })
            .catch(reject);
    });
};

export const postForm = (url: string, params: object) => {
    const data = new FormData();
    for (const [key, value] of Object.entries(params)) {
        data.append(key, value);
    }

    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            body: data
        })
            .then(response => {
                response.json()
                    .then(resolve)
                    .catch(reject);
            })
            .catch(reject);
    });
};

export const get = (url: string) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            headers: {
                "pragma": "no-cache",
                "cache-control": "no-cache"
            }
        })
            .then(response => {
                response.json()
                    .then(resolve)
                    .catch(reject);
            })
            .catch(reject);
    });
};

export const getAuthenticated = (url: string, token: string) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'GET',
            headers: {
                "x-access-token": token,
                "pragma": "no-cache",
                "cache-control": "no-cache"
            }
        })
            .then(response => {
                response.json()
                    .then(resolve)
                    .catch(reject);
            })
            .catch(reject);
    });
};

export const deleteBody = (url: string, params?: object) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json"
            },
            body: params ? JSON.stringify(params) : ''
        })
            .then(response => {
                response.json()
                    .then(resolve)
                    .catch(reject);
            })
            .catch(reject);
    });
};

export const deleteBodyAuthenticated = (url: string, token: string, params?: object) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token
            },
            body: params ? JSON.stringify(params) : ''
        })
            .then(response => {
                response.json()
                    .then(resolve)
                    .catch(reject);
            })
            .catch(reject);
    });
};
