import React, {FormEvent, useContext, useEffect, useRef, useState} from "react";
import {ResponseWrapper, RoleSummary} from "../../util/Types";
import {noError, rolePermissionsURL, roleURL, userURL} from "../../util/Globals";
import {deleteBodyAuthenticated, getAuthenticated, postBodyAuthenticated} from "../../util/Requests";
import {UserContext} from "../../login/UserContext";
import {Card, Col, Container, Form, ListGroup, Row} from "react-bootstrap";
import {IconButton} from "../../components/IconButton";
import {faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";

export const RolesPage: React.FC = (): JSX.Element => {

    const permissionInputRef = useRef(null);
    const roleNameInputRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | string[]>(noError);
    const [roles, setRoles] = useState<RoleSummary[]>([]);
    const [highlightedRole, setHighlightedRole] = useState('');

    const [rolePermissions, setRolePermissions] = useState<string[]>([]);

    const userContext = useContext(UserContext);

    const loadRoles = () => {
        setLoading(true);
        setError(noError);

        getAuthenticated(roleURL, userContext.user.token)
            .then((response) => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    console.log(wrapper.result);
                    setRoles(wrapper.result);

                    // Reset highlighted role if it doesn't exist for some reason (e.g. deleted)
                    if (highlightedRole !== '') {
                        let contained = false;
                        for (let role of roles) {
                            if (role.id === highlightedRole) {
                                contained = true;
                            }
                        }

                        if (!contained) {
                            setHighlightedRole('');
                        }
                    }
                } else {
                    setError(wrapper.error);
                }

                setLoading(false);
            });
    };

    const loadRolePermissions = (roleID: string) => {
        setLoading(true);
        setError(noError);

        const url = rolePermissionsURL + '?role=' + roleID;

        getAuthenticated(url, userContext.user.token)
            .then((response) => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    console.log(wrapper.result);
                    setRolePermissions(wrapper.result);
                } else {
                    setError(wrapper.error);
                }

                setLoading(false);
            });
    };

    const addRolePermissionHandler = (event?: FormEvent) => {
        if (event) {
            event.preventDefault();
        }

        setLoading(true);
        setError(noError);

        postBodyAuthenticated(rolePermissionsURL, userContext.user.token, {
            role: highlightedRole,
            // @ts-ignore
            permission: permissionInputRef.current.value
        })
            .then((response) => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    console.log(wrapper.result);
                    loadRolePermissions(highlightedRole);
                } else {
                    setError(wrapper.error);
                }

                setLoading(false);
            });
    };

    const revokeRolePermissionHandler = (permission: string) => {
        setError(noError);
        setLoading(true);

        deleteBodyAuthenticated(rolePermissionsURL, userContext.user.token, {
            role: highlightedRole,
            permission
        })
            .then((response) => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    console.log(wrapper.result);
                    loadRolePermissions(highlightedRole);
                } else {
                    setError(wrapper.error);
                }

                setLoading(false);
            });

    };

    const createRoleHandler = (event?: FormEvent) => {
        if (event) {
            event.preventDefault();
        }

        setLoading(true);
        setError(noError);

        postBodyAuthenticated(roleURL, userContext.user.token, {
            // @ts-ignore
            name: roleNameInputRef.current.value
        })
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    loadRoles();
                } else {
                    setError(wrapper.error);
                }

                setLoading(false);
            });
    };

    const deleteRoleHandler = (roleID: string) => {
        setError(noError);
        setLoading(true);

        deleteBodyAuthenticated(roleURL, userContext.user.token, {role: roleID})
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    loadRoles();
                } else {
                    setError(wrapper.error);
                }

                setLoading(false);
            });
    };

    const selectRole = (roleID: string) => {
        loadRolePermissions(roleID);
        setHighlightedRole(roleID);
    };

    useEffect(loadRoles, []);

    return (
        <Container>
            <VerticalPlaceholder height="1.5em"/>
            <Row>
                <Col>
                    <Card bg={"dark"} text={"white"}>
                        <Card.Header>
                            <Form inline onSubmit={createRoleHandler}>
                                <Form.Control ref={roleNameInputRef} className="mr-2" placeholder="Enter Role Name"/>

                                <IconButton onClick={createRoleHandler} variant="success" icon={faPlus}
                                            loading={loading}/>
                            </Form>
                        </Card.Header>
                        <Card.Body>
                            <ListGroup>
                                {roles.map(currentRole => (
                                    <ListGroup.Item
                                        className={currentRole.id === highlightedRole ? "activeListGroupItem" : "inactiveListGroupItem"}
                                        style={{textAlign: 'left', cursor: 'pointer'}}
                                        key={currentRole.id} onClick={() => selectRole(currentRole.id)}>
                                        <span>{currentRole.name}</span>
                                        <IconButton style={{float: 'right'}} variant={"danger"} icon={faTrashAlt}
                                                    loading={loading} onClick={() => {
                                            deleteRoleHandler(currentRole.id);
                                        }}/>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                        <Card.Footer>

                        </Card.Footer>
                    </Card>
                </Col>

                <Col>
                    <Card bg={"dark"} text={"white"}>
                        <Card.Header>
                            <Form inline onSubmit={addRolePermissionHandler}>
                                <Form.Control ref={permissionInputRef} className="mr-2" placeholder="Enter Permission"/>

                                <IconButton onClick={addRolePermissionHandler} variant="success" icon={faPlus}
                                            loading={loading}/>
                            </Form>
                        </Card.Header>
                        <Card.Body>
                            <ListGroup>
                                {rolePermissions.map(currentPermission => (
                                    <ListGroup.Item
                                        className="inactiveListGroupItem"
                                        style={{textAlign: 'left', cursor: 'pointer'}}
                                        key={currentPermission} onClick={() => selectRole(currentPermission)}>
                                        <span>{currentPermission}</span>
                                        <IconButton style={{float: 'right'}} variant={"danger"} icon={faTrashAlt}
                                                    loading={loading} onClick={() => {
                                            revokeRolePermissionHandler(currentPermission);
                                        }}/>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                        <Card.Footer>

                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};
