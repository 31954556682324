import React, {useState} from "react";
import {PageContext} from "./PageContext";
import {MainNavbar} from "./MainNavbar";
import { DashboardPage } from "../pages/dashboard/DashboardPage";
import { NavErrorPage } from "../pages/navError/NavErrorPage";
import {UsersPage} from "../pages/users/UsersPage";
import {RolesPage} from "../pages/roles/RolesPage";
import {TransactionsPage} from "../pages/transactions/TransactionsPage";
import {AssociationsPage} from "../pages/associations/AssociationsPage";

export const PageSwitch: React.FC = (): JSX.Element => {
    const [page, setPage] = useState('dashboard');
    const pageSwitch = (page: string) => setPage(page);

    let pageElement;
    switch (page) {
        case 'dashboard':
            pageElement = <DashboardPage/>;
            break;
        case 'users':
            pageElement = <UsersPage/>;
            break;
        case 'roles':
            pageElement = <RolesPage/>;
            break;
        case 'transactions':
            pageElement = <TransactionsPage/>;
            break;
        case 'associations':
            pageElement = <AssociationsPage/>;
            break;
        default:
            pageElement = <NavErrorPage/>;
    }

    return (
        <PageContext.Provider value={{page, setPage: pageSwitch}}>
            <MainNavbar/>
            {pageElement}
        </PageContext.Provider>
    );
};
